import React, {useContext, useRef} from 'react'

import ContextAPI from '../ContextAPI'

import Popup from './Popup'

export default function Printers() {
  const {printers, selectedPrinter, setSelectedPrinter} = useContext(ContextAPI)
  const button = useRef()

  const printerElements = printers.map((printer, i) => (
    <button
      ref={button}
      type="button"
      key={printer.printerSlug}
      onClick={(e) => {
        setSelectedPrinter(e.target.getAttribute('printer'))
      }}
      printer={printer.printerName}
      className={`printer-cart border-2 bg-opacity-10 p-2 ${
        selectedPrinter === printer.printerName
          ? 'activePrinter border-blue-200 shadow-xl'
          : 'bg-blue-700 scale-75 '
      } col-span-2 p-0 transform rounded-lg text-center flex flex-wrap justify-center content-end`}
    >
      <img
        className="m-2"
        src={printer.icon}
        width={
          printer.printerSlug === 'z3plus'
            ? '100%'
            : printer.printerSlug === 'z3'
            ? '100%'
            : printer.printerSlug === 'z2'
            ? '55%'
            : printer.printerSlug === 'x3'
            ? '55%'
            : printer.printerSlug === 'xliteplus'
            ? '55%'
            : ''
        }
        printer={printer.printerName}
        alt={`icon_of_${printer.printerSlug}`}
      />
      <div
        printer={printer.printerName}
        className="w-full text-center font-semibold text-md text-gray-600"
      >
        {printer.printerName}
      </div>
    </button>
  ))

  // eslint-disable-next-line array-callback-return
  const selectedPrinterContent = printers.map(function returnPrinters(printer) {
    if (printer.printerName === selectedPrinter) {
      return (
        <div
          key={`mainSectOf${printer.printerName}`}
          data-aos="fade-in"
          data-aos-easing="ease-in-out"
          className=" w-full justify-center flex flex-wrap content-start p-0 m-0"
          data-aos-duration="1000"
        >
          <div
            key={`printerContentOf${printer.printerSlug}`}
            className="w-full max-w-lg mx-auto flex flex-wrap text-center justify-center text-2xl font-semibold border-b border-gray-300 pb-3"
          >
            Zaxe {printer.printerName}
          </div>
          <div className="w-full flex text-center flex-wrap p-5 justify-center items-center content-center">
            <div className="flex flex-wrap justify-center content-center mx-auto p-0 m-0 max-w-lg">
              <img
                src={printer.image}
                className="mx-auto"
                alt={`image_of_${printer.printerSlug}`}
                width="100%"
              />
            </div>
          </div>
          <div className="w-full max-w-lg">
            <div className="w-full bg-white overflow-hidden rounded-2xl border shadow-xl flex flex-wrap justify-center content-center">
              {printer.printerContent.map((elm, i) =>
                i % 2 === 0 ? (
                  <div
                    key={`headerOf${printer.printerContent[i]}at${printer.printerName}`}
                    className="mx-auto pt-5 text-center w-full text-2xl font-semibold"
                  >
                    {elm}
                  </div>
                ) : (
                  <div
                    key={`descOf${printer.printerContent[i - 1]}at${
                      printer.printerName
                    }`}
                    className="mx-auto pb-5 border-b text-center w-full text-md "
                  >
                    {elm}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )
    }
  })

  return (
    <div className="container mx-auto p-0 m-0">
      <div className="w-full flex justify-center content-center p-0 m-0">
        <Popup />
      </div>
      <div
        data-aos="zoom-in"
        data-aos-delay="300"
        className="w-full  grid grid-cols-10 max-w-lg content-center mx-auto justify-center py-5 px-2"
      >
        {printerElements}
      </div>
      <div
        data-aos="zoom-in"
        data-aos-delay="500"
        className="w-full  justify-center flex flex-wrap"
      >
        <div className="my-2 w-full flex-wrap flex justify-center content-center px-2">
          <a
            id="contact-button"
            href="https://calendly.com/ege-insel/meeting-with-zaxe?month=2021-11"
            className="text-blue-600 relative inline-flex bg-blue-200 justify-center rounded-lg px-5 py-2"
          >
            Contact Us.
            <span className=" w-3 h-3 inline-flex absolute -top-1 -right-1 rounded-full bg-blue-400"></span>
            <span className=" animate-ping w-3 h-3 inline-flex  absolute -top-1 -right-1 rounded-full bg-blue-500"></span>
          </a>
        </div>
        <div className="text-center w-full text-gray-500 justify-center flex text-sm">
          <span className="text-blue-500">*</span>For more information, contact
          us.
        </div>
      </div>
      <div
        data-aos="fade-in"
        data-aos-delay="600"
        className="selected-printer-content w-full  flex flex-wrap p-5"
      >
        {selectedPrinterContent}
      </div>
    </div>
  )
}
