import React from 'react'

export default function Popup() {
  return (
    <div
      data-aos="zoom-in"
      className=" p-2 mt-5 n-0 w-full max-w-lg flex flex-wrap justify-center content-center"
    >
      <div className="popup bg-white border shadow-xl rounded-xl w-full py-5">
        <div className="w-full text-center  text-lg">
          <span className="text-green-500 font-semibold">%12</span> Discount at{' '}
          <span className="font-semibold">Zaxe 3D Printers</span>!
        </div>
        <div className="w-full text-center text-md pt-3 pb-0">
          Claim your coupon.
        </div>
        <div className="w-full text-center justify-center flex flex-wrap">
          <a
            href="https://store.zaxe.com/?coupon_code=formnext2021"
            target="_blank"
            rel="noreferrer"
            className=" bg-green-200 text-center focus:ring flex flex-wrap text-green-700 px-5 py-1 rounded-lg mt-5"
          >
            <svg
              style={{width: '20px'}}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
              />
            </svg>
            <div className="ml-2">Claim</div>
          </a>
        </div>
      </div>
    </div>
  )
}
