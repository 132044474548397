import React, {Component} from 'react'

import Z3plusIcon from './Images/Z3plus.svg'
import Z3Icon from './Images/Z3.svg'
import X3Icon from './Images/X3.svg'
import Z2Icon from './Images/Z2.svg'
import XLitePlusIcon from './Images/xliteplus.svg'

import Z3PlusImage from './Images/z3plus.webp'
import Z3Image from './Images/z3.webp'
import X3Image from './Images/x3.webp'
import Z2Image from './Images/z2.webp'
import XlitePlusImage from './Images/xliteplus.webp'

const ContextAPI = React.createContext()

class ContextProvider extends Component {
  state = {
    selectedPrinter: 'Z3',
    printers: [
      {
        printerName: 'Z3+',
        printerSlug: 'z3plus',
        image: Z3PlusImage,
        icon: Z3plusIcon,
        printerContent: [
          'User Interface',
          '7" Color Touch Screen',
          'Dimensions',
          '585 x 665 x 667 mm',
          'Build Volume',
          '300 x 300 x 300 mm',
          'Layer Resolution',
          '25 - 600 Micron',
          'Printhead',
          'Dual Printhead',
          'Print Area',
          'Closed, Heat Insulated',
          'Extruder Max',
          'Max. 280°C',
          'Bed Max Temp.',
          '100°C',
          'Nozzle Diameter',
          '0,2/0,4/0,6/0,8 mm',
          'Calibration',
          'Fully Automatic & Z Tilt',
          'Power Outage Protection',
          'Yes',
          'Filament Sensor',
          'Yes',
          'Filament Diameter',
          '1,75 mm',
          'Supported File Types',
          '.stl, .obj, .amf.',
          'Connections',
          'Wi-Fi, Ethernet, USB',
          'Filter',
          'HEPA + Carbon Filter',
          'Warranty Period',
          '24 Months',
          'Bed Material',
          'Flexible, Magnetic PEI Build Table',
          'Supported Materials',
          'PLA, ABS, PETG, Nylon, Flex and Carbonfiber, Wood, Copper, Metal Filled Filaments'
        ]
      },
      {
        printerName: 'Z3',
        printerSlug: 'z3',
        image: Z3Image,
        icon: Z3Icon,
        printerContent: [
          'User Interface',
          '7" Color Touch Screen',
          'Dimensions',
          '585 x 665 x 667 mm',
          'Build Volume',
          '400 x 300 x 350 mm',
          'Layer Resolution',
          '25 - 600 Micron',
          'Printhead',
          'Single Printhead',
          'Print Area',
          'Closed, Heat Insulated',
          'Extruder Max',
          'Max. 280°C',
          'Bed Max Temp.',
          '100°C',
          'Nozzle Diameter',
          '0,2/0,4/0,6/0,8 mm',
          'Calibration',
          'Fully Automatic & Z Tilt',
          'Power Outage Protection',
          'Yes',
          'Filament Sensor',
          'Yes',
          'Filament Diameter',
          '1,75 mm',
          'Supported File Types',
          '.stl, .obj, .amf.',
          'Connections',
          'Wi-Fi, Ethernet, USB',
          'Filter',
          'HEPA + Carbon Filter',
          'Warranty Period',
          '24 Months',
          'Bed Material',
          'Flexible, Magnetic PEI Build Table',
          'Supported Materials',
          'PLA, ABS, PETG, Nylon, Flex and Carbonfiber, Wood, Copper, Metal Filled Filaments'
        ]
      },
      {
        printerName: 'Z2',
        printerSlug: 'z2',
        image: Z2Image,
        icon: Z2Icon,
        printerContent: [
          'User Interface',
          '7" Color Touch Screen',
          'Dimensions',
          '410 x 583 x 530 mm',
          'Build Volume',
          '220 x 220 x 250 mm',
          'Layer Resolution',
          '25 - 600 Micron',
          'Printhead',
          'Single Printhead',
          'Print Area',
          'Closed, Heat Insulated',
          'Extruder Max',
          'Max. 280°C',
          'Bed Max Temp.',
          '100°C',
          'Nozzle Diameter',
          '0,2/0,4/0,6/0,8 mm',
          'Calibration',
          'Fully Automatic',
          'Power Outage Protection',
          'Yes',
          'Filament Sensor',
          'Yes',
          'Filament Diameter',
          '1,75 mm',
          'Supported File Types',
          '.stl, .obj, .amf.',
          'Connections',
          'Wi-Fi, Ethernet, USB',
          'Filter',
          'HEPA + Carbon Filter',
          'Warranty Period',
          '24 Months',
          'Bed Material',
          'Flexible, Magnetic PEI Build Table',
          'Supported Materials',
          'PLA, ABS, PETG, Nylon, Flex and Carbonfiber, Wood, Copper, Metal Filled Filaments'
        ]
      },
      {
        printerName: 'X3',
        printerSlug: 'x3',
        image: X3Image,
        icon: X3Icon,
        printerContent: [
          'User Interface',
          '5" Color Touch Screen',
          'Dimensions',
          '410 x 583 x 530 mm',
          'Build Volume',
          '220 x 220 x 250 mm',
          'Layer Resolution',
          '25 - 600 Micron',
          'Printhead',
          'Single Printhead',
          'Print Area',
          'Closed, Heat Insulated',
          'Extruder Max',
          'Max. 280°C',
          'Bed Max Temp.',
          '100°C',
          'Nozzle Diameter',
          '0,2/0,4/0,6/0,8 mm',
          'Calibration',
          'Fully Automatic',
          'Filament Sensor',
          'Yes',
          'Filament Diameter',
          '1,75 mm',
          'Supported File Types',
          '.stl, .obj, .amf.',
          'Connections',
          'Wi-Fi, Ethernet, USB',
          'Filter',
          'HEPA + Carbon Filter',
          'Warranty Period',
          '24 Months',
          'Bed Material',
          'Flexible, Magnetic PEI Build Table',
          'Supported Materials',
          'PLA, ABS, PETG, Nylon, Flex and Carbonfiber, Wood, Copper, Metal Filled Filaments'
        ]
      },
      {
        printerName: 'xlite+',
        printerSlug: 'xliteplus',
        image: XlitePlusImage,
        icon: XLitePlusIcon,
        printerContent: [
          'User Interface',
          '5" Color Touch Screen',
          'Dimensions',
          '503 x 370 x 430 mm',
          'Build Volume',
          '220 x 230 x 300 mm',
          'Layer Resolution',
          '25 - 600 Micron',
          'Printhead',
          'Single Printhead',
          'Print Area',
          'Open',
          'Extruder Max',
          'Max. 280°C',
          'Bed Max Temp.',
          '100°C',
          'Nozzle Diameter',
          '0,2/0,4/0,6/0,8 mm',
          'Calibration',
          'Fully Automatic',
          'Power Outage Protection',
          'Yes',
          'Filament Sensor',
          'Yes',
          'Filament Diameter',
          '1,75 mm',
          'Supported File Types',
          '.stl, .obj, .amf.',
          'Connections',
          'Wi-Fi, Ethernet, USB',
          'Filter',
          'No filter',
          'Warranty Period',
          '24 Months',
          'Bed Material',
          'Flexible, Magnetic PEI Build Table',
          'Supported Materials',
          'PLA, ABS, PETG, FLEX'
        ]
      }
    ]
  }

  setSelectedPrinter = (selectedPrinter) => {
    this.setState((prev) => ({selectedPrinter}))
  }

  render() {
    const {children} = this.props
    const {printers, selectedPrinter} = this.state
    const {setSelectedPrinter} = this

    return (
      <ContextAPI.Provider
        value={{printers, selectedPrinter, setSelectedPrinter}}
      >
        {children}
      </ContextAPI.Provider>
    )
  }
}

export default ContextAPI

export {ContextProvider}
