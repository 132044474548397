import React from 'react'

export default function Footer() {
  return (
    <div
      style={{backgroundColor: '#231F20'}}
      className="w-full text-center justify-center flex flex-wrap mt-32 text-white py-10 px-2"
    >
      <div className="w-full text-center flex justify-center content-center">
        <img
          alt="zaxe_logo_white"
          src="https://www.zaxe.com/public/svg-main/zaxe-logo-white-transparent.svg"
          width="180"
        />
      </div>
      Copyright © 2021 Zaxe 3D Printing Technologies
    </div>
  )
}
