import AOS from 'aos'
import 'aos/dist/aos.css'
import {ContextProvider} from './ContextAPI'

import Header from './Components/Header'
import Printers from './Components/Printers'
import Footer from './Components/Footer'

AOS.init()
function App() {
  return (
    <ContextProvider value="null">
      <div className="w-full p-0 m-0">
        <Header />
        <Printers />
        <Footer />
      </div>
    </ContextProvider>
  )
}

export default App
