import React from 'react'

import ZaxeLogo from '../Images/zaxe_logo.svg'

export default function Header() {
  return (
    <div className="w-full shadow-xl flex flex-wrap justify-center py-3">
      <a href="https://zaxe.com">
        <img src={ZaxeLogo} alt="zaxe_logo" width="90" />
      </a>
    </div>
  )
}
